import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Demo from './Demo';
import 'bootstrap/dist/css/bootstrap.min.css';



function App() {
  return (
    <div className="App">
      
      <Router>
      <Routes>
        <Route path='/' element={<Demo/>}/>
      </Routes>
    </Router>
    
    </div>
  );
}

export default App;
