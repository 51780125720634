import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Paginator } from "primereact/paginator";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import uk from "./assest/New folder/UK.png";
import us from "./assest/New folder/US.png";
// import Button from 'react-bootstrap/Button';
import Modal from "react-bootstrap/Modal";
import { notification } from "antd";

function Demo() {
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [perPage, setPerPage] = useState(50);
  const count = [{ name: 50 }, { name: 100 }, { name: 200 }];
  const [selectedBooks, setSelectedBooks] = useState({});
  const [show, setShow] = useState(false);
  const [taginputValue, setTaginputValue] = useState("");

  const [addTagdatas, setAddTagDatas] = useState({});
  const [nodes, setNodes] = useState([
    {
      key: "0",
      data: {
        name: "The Power",
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJaF4cwjGAUNOdQA8e708Ia_h7ZLpJIzJ4aA&s",
        bookId: "14554d55",
        targetACOS: 1.2,
        bsr: [
          { country: "UK", rank: "#12" },
          { country: "US", rank: "#1298" },
        ],
        unitSold: "10,000",
        royalities: "$1,000",
        adspend: "$1,000",
        price: "$25",
        tags: ["Self-help", "War"],
        type: "Mixed",
        marketplace: "Mixed",
        acos: "32.34%",
        ctr: "76.44%",
        clicks: 23,
        cr: "3.3%",
        cpc: "$67",
        impression: "1,412",
        orders: "1,000",
        sales: "$30",
        pageLoad: "10,000",
        royalitiesPageRead: "$123.00",
        status: "live",
        author: "lily",
        bookFormat: "Kindle Vella",
        publishDate: "31 May 2023",
      },
      children: [
        {
          key: "0-0",
          data: {
            name: "Black Friday",
            unitSold: "10,000",
            royalities: "$1,000",
            adspend: "$1,000",
            type: "Auto",
            marketplace: "UK",
            acos: "32.34%",
            ctr: "76.44%",
            clicks: 23,
            cr: "3.3%",
            cpc: "$67",
            impression: "1,412",
            royalitiesPageRead: "$50.00",
            orders: "1,000",
            sales: "$30",
            pageLoad: "10,000",
            status: "Running",
          },
          children: [
            {
              key: "0-0-0",
              data: {
                name: "New Arrivals",
                unitSold: "10,000",
                royalities: "$1,000",
                adspend: "$1,000",
                type: "Auto",
                marketplace: "UK",
                acos: "32.34%",
                ctr: "76.44%",
                clicks: 23,
                cr: "3.3%",
                cpc: "$67",
                impression: "1,412",
                royalitiesPageRead: "$50.00",
                orders: "1,000",
                sales: "$30",
                pageLoad: "10,000",
                status: "Running",
              },
            },
            {
              key: "0-0-1",
              data: {
                name: "Specials",
                unitSold: "10,000",
                royalities: "$1,000",
                adspend: "$1,000",
                type: "Auto",
                marketplace: "UK",
                acos: "32.34%",
                ctr: "76.44%",
                clicks: 23,
                cr: "3.3%",
                cpc: "$67",
                impression: "1,412",
                royalitiesPageRead: "$50.00",
                orders: "1,000",
                sales: "$30",
                pageLoad: "10,000",
                status: "Running",
              },
            },
          ],
        },
        {
          key: "0-1",
          data: {
            name: "Best Seller",
            unitSold: "10,000",
            royalities: "$1,000",
            adspend: "$1,000",
            type: "Auto",
            marketplace: "UK",
            acos: "32.34%",
            ctr: "76.44%",
            clicks: 23,
            cr: "3.3%",
            cpc: "$67",
            impression: "1,412",
            royalitiesPageRead: "$50.00",
            orders: "1,000",
            sales: "$30",
            pageLoad: "10,000",
            status: "Running",
          },
        },
        {
          key: "0-2",
          data: {
            name: "Summer Reads",
            unitSold: "10,000",
            royalities: "$1,000",
            adspend: "$1,000",
            type: "Auto",
            marketplace: "UK",
            acos: "32.34%",
            ctr: "76.44%",
            clicks: 23,
            cr: "3.3%",
            cpc: "$67",
            impression: "1,412",
            royalitiesPageRead: "$50.00",
            orders: "1,000",
            sales: "$30",
            pageLoad: "10,000",
            status: "Running",
          },
        },
      ],
    },
    {
      key: "1",
      data: {
        name: "Inception",
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTy4PWJGBS_nuPz2ELIPKv0WngGRzCd6Lv64w&s",
        bookId: "28374k84",
        targetACOS: 1.3,
        bsr: [
          { country: "UK", rank: "#34" },
          { country: "US", rank: "#890" },
        ],
        unitSold: "15,000",
        royalities: "$1,500",
        adspend: "$1,500",
        price: "$30",
        tags: ["Adventure", "Thriller"],
        type: "Mixed",
        marketplace: "Mixed",
        acos: "28.14%",
        ctr: "82.44%",
        clicks: 30,
        cr: "4.0%",
        cpc: "$50",
        impression: "2,000",
        orders: "1,200",
        sales: "$40",
        pageLoad: "12,000",
        royalitiesPageRead: "$150.00",
        status: "live",
        author: "John Doe",
        bookFormat: "Paperback",
        publishDate: "15 June 2023",
      },
      children: [
        {
          key: "1-0",
          data: {
            name: "Holiday Special",
            unitSold: "12,000",
            royalities: "$1,200",
            adspend: "$1,200",
            type: "Auto",
            marketplace: "UK",
            acos: "28.14%",
            ctr: "82.44%",
            clicks: 30,
            cr: "4.0%",
            cpc: "$50",
            impression: "2,000",
            royalitiesPageRead: "$60.00",
            orders: "1,200",
            sales: "$40",
            pageLoad: "12,000",
            status: "Running",
          },
          children: [
            {
              key: "1-0-0",
              data: {
                name: "New Releases",
                unitSold: "12,000",
                royalities: "$1,200",
                adspend: "$1,200",
                type: "Auto",
                marketplace: "UK",
                acos: "28.14%",
                ctr: "82.44%",
                clicks: 30,
                cr: "4.0%",
                cpc: "$50",
                impression: "2,000",
                royalitiesPageRead: "$60.00",
                orders: "1,200",
                sales: "$40",
                pageLoad: "12,000",
                status: "Running",
              },
            },
            {
              key: "1-0-1",
              data: {
                name: "Top Picks",
                unitSold: "12,000",
                royalities: "$1,200",
                adspend: "$1,200",
                type: "Auto",
                marketplace: "UK",
                acos: "28.14%",
                ctr: "82.44%",
                clicks: 30,
                cr: "4.0%",
                cpc: "$50",
                impression: "2,000",
                royalitiesPageRead: "$60.00",
                orders: "1,200",
                sales: "$40",
                pageLoad: "12,000",
                status: "Running",
              },
            },
          ],
        },
        {
          key: "1-1",
          data: {
            name: "Blockbuster",
            unitSold: "12,000",
            royalities: "$1,200",
            adspend: "$1,200",
            type: "Auto",
            marketplace: "UK",
            acos: "28.14%",
            ctr: "82.44%",
            clicks: 30,
            cr: "4.0%",
            cpc: "$50",
            impression: "2,000",
            royalitiesPageRead: "$60.00",
            orders: "1,200",
            sales: "$40",
            pageLoad: "12,000",
            status: "Running",
          },
        },
        {
          key: "1-2",
          data: {
            name: "Winter Reads",
            unitSold: "12,000",
            royalities: "$1,200",
            adspend: "$1,200",
            type: "Auto",
            marketplace: "UK",
            acos: "28.14%",
            ctr: "82.44%",
            clicks: 30,
            cr: "4.0%",
            cpc: "$50",
            impression: "2,000",
            royalitiesPageRead: "$60.00",
            orders: "1,200",
            sales: "$40",
            pageLoad: "12,000",
            status: "Running",
          },
        },
      ],
    },
    {
      key: "2",
      data: {
        name: "The Alchemist",
        imageUrl:
          "https://bookbins.in/wp-content/uploads/2024/04/The-Alchemist-Paulo-Coelho-Buy-Online-Bookbins-1.png",
        bookId: "36789f88",
        targetACOS: 1.1,
        bsr: [
          { country: "UK", rank: "#56" },
          { country: "US", rank: "#678" },
        ],
        unitSold: "20,000",
        royalities: "$2,000",
        adspend: "$2,000",
        price: "$35",
        tags: ["Fiction", "Drama"],
        type: "Mixed",
        marketplace: "Mixed",
        acos: "25.34%",
        ctr: "85.44%",
        clicks: 40,
        cr: "5.0%",
        cpc: "$60",
        impression: "2,500",
        orders: "1,500",
        sales: "$50",
        pageLoad: "15,000",
        royalitiesPageRead: "$180.00",
        status: "live",
        author: "Jane Smith",
        bookFormat: "Hardcover",
        publishDate: "20 July 2023",
      },
      children: [
        {
          key: "2-0",
          data: {
            name: "Autumn Picks",
            unitSold: "15,000",
            royalities: "$1,500",
            adspend: "$1,500",
            type: "Auto",
            marketplace: "US",
            acos: "25.34%",
            ctr: "85.44%",
            clicks: 40,
            cr: "5.0%",
            cpc: "$60",
            impression: "2,500",
            royalitiesPageRead: "$75.00",
            orders: "1,500",
            sales: "$50",
            pageLoad: "15,000",
            status: "Running",
          },
          children: [
            {
              key: "2-0-0",
              data: {
                name: "Best of the Year",
                unitSold: "15,000",
                royalities: "$1,500",
                adspend: "$1,500",
                type: "Auto",
                marketplace: "US",
                acos: "25.34%",
                ctr: "85.44%",
                clicks: 40,
                cr: "5.0%",
                cpc: "$60",
                impression: "2,500",
                royalitiesPageRead: "$75.00",
                orders: "1,500",
                sales: "$50",
                pageLoad: "15,000",
                status: "Running",
              },
            },
            {
              key: "2-0-1",
              data: {
                name: "Holiday Reads",
                unitSold: "15,000",
                royalities: "$1,500",
                adspend: "$1,500",
                type: "Auto",
                marketplace: "US",
                acos: "25.34%",
                ctr: "85.44%",
                clicks: 40,
                cr: "5.0%",
                cpc: "$60",
                impression: "2,500",
                royalitiesPageRead: "$75.00",
                orders: "1,500",
                sales: "$50",
                pageLoad: "15,000",
                status: "Running",
              },
            },
          ],
        },
        {
          key: "2-1",
          data: {
            name: "Top Charts",
            unitSold: "15,000",
            royalities: "$1,500",
            adspend: "$1,500",
            type: "Auto",
            marketplace: "US",
            acos: "25.34%",
            ctr: "85.44%",
            clicks: 40,
            cr: "5.0%",
            cpc: "$60",
            impression: "2,500",
            royalitiesPageRead: "$75.00",
            orders: "1,500",
            sales: "$50",
            pageLoad: "15,000",
            status: "Running",
          },
        },
        {
          key: "2-2",
          data: {
            name: "Reader's Choice",
            unitSold: "15,000",
            royalities: "$1,500",
            adspend: "$1,500",
            type: "Auto",
            marketplace: "US",
            acos: "25.34%",
            ctr: "85.44%",
            clicks: 40,
            cr: "5.0%",
            cpc: "$60",
            impression: "2,500",
            royalitiesPageRead: "$75.00",
            orders: "1,500",
            sales: "$50",
            pageLoad: "15,000",
            status: "Running",
          },
        },
      ],
    },
    {
      key: "3",
      data: {
        name: "Think and Grow Rich",
        imageUrl:
          "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1463241782i/30186948.jpg",
        bookId: "47890g99",
        targetACOS: 1.5,
        bsr: [
          { country: "UK", rank: "#45" },
          { country: "US", rank: "#350" },
        ],
        unitSold: "25,000",
        royalities: "$2,500",
        adspend: "$2,500",
        price: "$40",
        tags: ["Science Fiction", "Adventure"],
        type: "Mixed",
        marketplace: "Mixed",
        acos: "22.34%",
        ctr: "90.44%",
        clicks: 50,
        cr: "6.0%",
        cpc: "$55",
        impression: "3,000",
        orders: "2,000",
        sales: "$60",
        pageLoad: "20,000",
        royalitiesPageRead: "$200.00",
        status: "live",
        author: "Emily Clark",
        bookFormat: "Ebook",
        publishDate: "01 August 2023",
      },
      children: [
        {
          key: "3-0",
          data: {
            name: "Summer Blockbuster",
            unitSold: "20,000",
            royalities: "$2,000",
            adspend: "$2,000",
            type: "Auto",
            marketplace: "Mixed",
            acos: "22.34%",
            ctr: "90.44%",
            clicks: 50,
            cr: "6.0%",
            cpc: "$55",
            impression: "3,000",
            royalitiesPageRead: "$100.00",
            orders: "2,000",
            sales: "$60",
            pageLoad: "20,000",
            status: "Running",
          },
          children: [
            {
              key: "3-0-0",
              data: {
                name: "Fan Favorites",
                unitSold: "20,000",
                royalities: "$2,000",
                adspend: "$2,000",
                type: "Auto",
                marketplace: "Mixed",
                acos: "22.34%",
                ctr: "90.44%",
                clicks: 50,
                cr: "6.0%",
                cpc: "$55",
                impression: "3,000",
                royalitiesPageRead: "$100.00",
                orders: "2,000",
                sales: "$60",
                pageLoad: "20,000",
                status: "Running",
              },
            },
            {
              key: "3-0-1",
              data: {
                name: "Summer Picks",
                unitSold: "20,000",
                royalities: "$2,000",
                adspend: "$2,000",
                type: "Auto",
                marketplace: "Mixed",
                acos: "22.34%",
                ctr: "90.44%",
                clicks: 50,
                cr: "6.0%",
                cpc: "$55",
                impression: "3,000",
                royalitiesPageRead: "$100.00",
                orders: "2,000",
                sales: "$60",
                pageLoad: "20,000",
                status: "Running",
              },
            },
          ],
        },
        {
          key: "3-1",
          data: {
            name: "New Releases",
            unitSold: "20,000",
            royalities: "$2,000",
            adspend: "$2,000",
            type: "Auto",
            marketplace: "Mixed",
            acos: "22.34%",
            ctr: "90.44%",
            clicks: 50,
            cr: "6.0%",
            cpc: "$55",
            impression: "3,000",
            royalitiesPageRead: "$100.00",
            orders: "2,000",
            sales: "$60",
            pageLoad: "20,000",
            status: "Running",
          },
        },
        {
          key: "3-2",
          data: {
            name: "Editor's Choice",
            unitSold: "20,000",
            royalities: "$2,000",
            adspend: "$2,000",
            type: "Auto",
            marketplace: "Mixed",
            acos: "22.34%",
            ctr: "90.44%",
            clicks: 50,
            cr: "6.0%",
            cpc: "$55",
            impression: "3,000",
            royalitiesPageRead: "$100.00",
            orders: "2,000",
            sales: "$60",
            pageLoad: "20,000",
            status: "Running",
          },
        },
      ],
    },
    {
      key: "4",
      data: {
        name: "Rich Dad Poor Dad",
        imageUrl:
          "https://5.imimg.com/data5/HB/LA/MY-11183377/rich-dad-poor-dad.png",
        bookId: "58901h00",
        targetACOS: 1.8,
        bsr: [
          { country: "UK", rank: "#67" },
          { country: "US", rank: "#456" },
        ],
        unitSold: "30,000",
        royalities: "$3,000",
        adspend: "$3,000",
        price: "$45",
        tags: ["Space", "Adventure"],
        type: "Mixed",
        marketplace: "Mixed",
        acos: "18.34%",
        ctr: "88.44%",
        clicks: 60,
        cr: "7.0%",
        cpc: "$65",
        impression: "4,000",
        orders: "2,500",
        sales: "$70",
        pageLoad: "25,000",
        royalitiesPageRead: "$220.00",
        status: "live",
        author: "John Doe",
        bookFormat: "Paperback",
        publishDate: "05 August 2023",
      },
      children: [
        {
          key: "4-0",
          data: {
            name: "Space Odyssey",
            unitSold: "25,000",
            royalities: "$2,500",
            adspend: "$2,500",
            type: "Auto",
            marketplace: "Mixed",
            acos: "18.34%",
            ctr: "88.44%",
            clicks: 60,
            cr: "7.0%",
            cpc: "$65",
            impression: "4,000",
            royalitiesPageRead: "$120.00",
            orders: "2,500",
            sales: "$70",
            pageLoad: "25,000",
            status: "Running",
          },
          children: [
            {
              key: "4-0-0",
              data: {
                name: "Top Picks",
                unitSold: "25,000",
                royalities: "$2,500",
                adspend: "$2,500",
                type: "Auto",
                marketplace: "Mixed",
                acos: "18.34%",
                ctr: "88.44%",
                clicks: 60,
                cr: "7.0%",
                cpc: "$65",
                impression: "4,000",
                royalitiesPageRead: "$120.00",
                orders: "2,500",
                sales: "$70",
                pageLoad: "25,000",
                status: "Running",
              },
            },
            {
              key: "4-0-1",
              data: {
                name: "Editor's Picks",
                unitSold: "25,000",
                royalities: "$2,500",
                adspend: "$2,500",
                type: "Auto",
                marketplace: "Mixed",
                acos: "18.34%",
                ctr: "88.44%",
                clicks: 60,
                cr: "7.0%",
                cpc: "$65",
                impression: "4,000",
                royalitiesPageRead: "$120.00",
                orders: "2,500",
                sales: "$70",
                pageLoad: "25,000",
                status: "Running",
              },
            },
          ],
        },
        {
          key: "4-1",
          data: {
            name: "Sci-Fi Classics",
            unitSold: "25,000",
            royalities: "$2,500",
            adspend: "$2,500",
            type: "Auto",
            marketplace: "Mixed",
            acos: "18.34%",
            ctr: "88.44%",
            clicks: 60,
            cr: "7.0%",
            cpc: "$65",
            impression: "4,000",
            royalitiesPageRead: "$120.00",
            orders: "2,500",
            sales: "$70",
            pageLoad: "25,000",
            status: "Running",
          },
        },
        {
          key: "4-2",
          data: {
            name: "Bestsellers",
            unitSold: "25,000",
            royalities: "$2,500",
            adspend: "$2,500",
            type: "Auto",
            marketplace: "Mixed",
            acos: "18.34%",
            ctr: "88.44%",
            clicks: 60,
            cr: "7.0%",
            cpc: "$65",
            impression: "4,000",
            royalitiesPageRead: "$120.00",
            orders: "2,500",
            sales: "$70",
            pageLoad: "25,000",
            status: "Running",
          },
        },
      ],
    },
    {
      key: "5",
      data: {
        name: "Man's Search for Meaning",
        imageUrl:
          "https://m.media-amazon.com/images/I/61157LApbuL._AC_UF350,350_QL50_.jpg",
        bookId: "67891i12",
        targetACOS: 1.3,
        bsr: [
          { country: "UK", rank: "#89" },
          { country: "US", rank: "#789" },
        ],
        unitSold: "35,000",
        royalities: "$3,500",
        adspend: "$3,500",
        price: "$50",
        tags: ["Fantasy", "Magic"],
        type: "Mixed",
        marketplace: "Mixed",
        acos: "20.34%",
        ctr: "92.44%",
        clicks: 70,
        cr: "8.0%",
        cpc: "$75",
        impression: "5,000",
        orders: "3,000",
        sales: "$80",
        pageLoad: "30,000",
        royalitiesPageRead: "$250.00",
        status: "live",
        author: "Sarah Johnson",
        bookFormat: "Hardcover",
        publishDate: "10 August 2023",
      },
      children: [
        {
          key: "5-0",
          data: {
            name: "Magic and Mystery",
            unitSold: "30,000",
            royalities: "$3,000",
            adspend: "$3,000",
            type: "Auto",
            marketplace: "Mixed",
            acos: "20.34%",
            ctr: "92.44%",
            clicks: 70,
            cr: "8.0%",
            cpc: "$75",
            impression: "5,000",
            royalitiesPageRead: "$150.00",
            orders: "3,000",
            sales: "$80",
            pageLoad: "30,000",
            status: "Running",
          },
          children: [
            {
              key: "5-0-0",
              data: {
                name: "Fantasy Favorites",
                unitSold: "30,000",
                royalities: "$3,000",
                adspend: "$3,000",
                type: "Auto",
                marketplace: "Mixed",
                acos: "20.34%",
                ctr: "92.44%",
                clicks: 70,
                cr: "8.0%",
                cpc: "$75",
                impression: "5,000",
                royalitiesPageRead: "$150.00",
                orders: "3,000",
                sales: "$80",
                pageLoad: "30,000",
                status: "Running",
              },
            },
            {
              key: "5-0-1",
              data: {
                name: "Top Reads",
                unitSold: "30,000",
                royalities: "$3,000",
                adspend: "$3,000",
                type: "Auto",
                marketplace: "Mixed",
                acos: "20.34%",
                ctr: "92.44%",
                clicks: 70,
                cr: "8.0%",
                cpc: "$75",
                impression: "5,000",
                royalitiesPageRead: "$150.00",
                orders: "3,000",
                sales: "$80",
                pageLoad: "30,000",
                status: "Running",
              },
            },
          ],
        },
        {
          key: "5-1",
          data: {
            name: "Enchanted Reads",
            unitSold: "30,000",
            royalities: "$3,000",
            adspend: "$3,000",
            type: "Auto",
            marketplace: "Mixed",
            acos: "20.34%",
            ctr: "92.44%",
            clicks: 70,
            cr: "8.0%",
            cpc: "$75",
            impression: "5,000",
            royalitiesPageRead: "$150.00",
            orders: "3,000",
            sales: "$80",
            pageLoad: "30,000",
            status: "Running",
          },
        },
        {
          key: "5-2",
          data: {
            name: "Best of Fantasy",
            unitSold: "30,000",
            royalities: "$3,000",
            adspend: "$3,000",
            type: "Auto",
            marketplace: "Mixed",
            acos: "20.34%",
            ctr: "92.44%",
            clicks: 70,
            cr: "8.0%",
            cpc: "$75",
            impression: "5,000",
            royalitiesPageRead: "$150.00",
            orders: "3,000",
            sales: "$80",
            pageLoad: "30,000",
            status: "Running",
          },
        },
      ],
    },
    {
      key: "6",
      data: {
        name: "Atomic Habits",
        imageUrl:
          "https://jamesclear.com/wp-content/uploads/2024/06/atomic-habits-dots.png",
        bookId: "78902j34",
        targetACOS: 1.5,
        bsr: [
          { country: "UK", rank: "#90" },
          { country: "US", rank: "#678" },
        ],
        unitSold: "40,000",
        royalities: "$4,000",
        adspend: "$4,000",
        price: "$55",
        tags: ["History", "Epic"],
        type: "Mixed",
        marketplace: "Mixed",
        acos: "25.34%",
        ctr: "95.44%",
        clicks: 80,
        cr: "9.0%",
        cpc: "$85",
        impression: "6,000",
        orders: "3,500",
        sales: "$90",
        pageLoad: "35,000",
        royalitiesPageRead: "$300.00",
        status: "live",
        author: "Robert King",
        bookFormat: "Hardcover",
        publishDate: "15 August 2023",
      },
      children: [
        {
          key: "6-0",
          data: {
            name: "Epic Battles",
            unitSold: "35,000",
            royalities: "$3,500",
            adspend: "$3,500",
            type: "Auto",
            marketplace: "Mixed",
            acos: "25.34%",
            ctr: "95.44%",
            clicks: 80,
            cr: "9.0%",
            cpc: "$85",
            impression: "6,000",
            royalitiesPageRead: "$200.00",
            orders: "3,500",
            sales: "$90",
            pageLoad: "35,000",
            status: "Running",
          },
          children: [
            {
              key: "6-0-0",
              data: {
                name: "Top Sellers",
                unitSold: "35,000",
                royalities: "$3,500",
                adspend: "$3,500",
                type: "Auto",
                marketplace: "Mixed",
                acos: "25.34%",
                ctr: "95.44%",
                clicks: 80,
                cr: "9.0%",
                cpc: "$85",
                impression: "6,000",
                royalitiesPageRead: "$200.00",
                orders: "3,500",
                sales: "$90",
                pageLoad: "35,000",
                status: "Running",
              },
            },
            {
              key: "6-0-1",
              data: {
                name: "Editor's Picks",
                unitSold: "35,000",
                royalities: "$3,500",
                adspend: "$3,500",
                type: "Auto",
                marketplace: "Mixed",
                acos: "25.34%",
                ctr: "95.44%",
                clicks: 80,
                cr: "9.0%",
                cpc: "$85",
                impression: "6,000",
                royalitiesPageRead: "$200.00",
                orders: "3,500",
                sales: "$90",
                pageLoad: "35,000",
                status: "Running",
              },
            },
          ],
        },
        {
          key: "6-1",
          data: {
            name: "Historical Favorites",
            unitSold: "35,000",
            royalities: "$3,500",
            adspend: "$3,500",
            type: "Auto",
            marketplace: "Mixed",
            acos: "25.34%",
            ctr: "95.44%",
            clicks: 80,
            cr: "9.0%",
            cpc: "$85",
            impression: "6,000",
            royalitiesPageRead: "$200.00",
            orders: "3,500",
            sales: "$90",
            pageLoad: "35,000",
            status: "Running",
          },
        },
        {
          key: "6-2",
          data: {
            name: "Epic Reads",
            unitSold: "35,000",
            royalities: "$3,500",
            adspend: "$3,500",
            type: "Auto",
            marketplace: "Mixed",
            acos: "25.34%",
            ctr: "95.44%",
            clicks: 80,
            cr: "9.0%",
            cpc: "$85",
            impression: "6,000",
            royalitiesPageRead: "$200.00",
            orders: "3,500",
            sales: "$90",
            pageLoad: "35,000",
            status: "Running",
          },
        },
      ],
    },
    {
      key: "7",
      data: {
        name: "Collapse",
        imageUrl: "https://m.media-amazon.com/images/I/61LA4aWiGzL._SY466_.jpg",
        bookId: "78902j34",
        targetACOS: 1.7,
        bsr: [
          { country: "UK", rank: "#123" },
          { country: "US", rank: "#321" },
        ],
        unitSold: "50,000",
        royalities: "$5,000",
        adspend: "$5,000",
        price: "$60",
        tags: ["Romance", "Contemporary"],
        type: "Mixed",
        marketplace: "Mixed",
        acos: "15.34%",
        ctr: "98.44%",
        clicks: 90,
        cr: "10.0%",
        cpc: "$90",
        impression: "8,000",
        orders: "4,000",
        sales: "$100",
        pageLoad: "40,000",
        royalitiesPageRead: "$350.00",
        status: "live",
        author: "Emily Clark",
        bookFormat: "Hardcover",
        publishDate: "20 August 2023",
      },
      children: [
        {
          key: "7-0",
          data: {
            name: "Romantic Reads",
            unitSold: "45,000",
            royalities: "$4,500",
            adspend: "$4,500",
            type: "Auto",
            marketplace: "Mixed",
            acos: "15.34%",
            ctr: "98.44%",
            clicks: 90,
            cr: "10.0%",
            cpc: "$90",
            impression: "8,000",
            royalitiesPageRead: "$250.00",
            orders: "4,000",
            sales: "$100",
            pageLoad: "40,000",
            status: "Running",
          },
          children: [
            {
              key: "7-0-0",
              data: {
                name: "Top Romance",
                unitSold: "45,000",
                royalities: "$4,500",
                adspend: "$4,500",
                type: "Auto",
                marketplace: "Mixed",
                acos: "15.34%",
                ctr: "98.44%",
                clicks: 90,
                cr: "10.0%",
                cpc: "$90",
                impression: "8,000",
                royalitiesPageRead: "$250.00",
                orders: "4,000",
                sales: "$100",
                pageLoad: "40,000",
                status: "Running",
              },
            },
            {
              key: "7-0-1",
              data: {
                name: "Romantic Favorites",
                unitSold: "45,000",
                royalities: "$4,500",
                adspend: "$4,500",
                type: "Auto",
                marketplace: "Mixed",
                acos: "15.34%",
                ctr: "98.44%",
                clicks: 90,
                cr: "10.0%",
                cpc: "$90",
                impression: "8,000",
                royalitiesPageRead: "$250.00",
                orders: "4,000",
                sales: "$100",
                pageLoad: "40,000",
                status: "Running",
              },
            },
          ],
        },
        {
          key: "7-1",
          data: {
            name: "Top Sellers",
            unitSold: "45,000",
            royalities: "$4,500",
            adspend: "$4,500",
            type: "Auto",
            marketplace: "Mixed",
            acos: "15.34%",
            ctr: "98.44%",
            clicks: 90,
            cr: "10.0%",
            cpc: "$90",
            impression: "8,000",
            royalitiesPageRead: "$250.00",
            orders: "4,000",
            sales: "$100",
            pageLoad: "40,000",
            status: "Running",
          },
        },
        {
          key: "7-2",
          data: {
            name: "Best Romance",
            unitSold: "45,000",
            royalities: "$4,500",
            adspend: "$4,500",
            type: "Auto",
            marketplace: "Mixed",
            acos: "15.34%",
            ctr: "98.44%",
            clicks: 90,
            cr: "10.0%",
            cpc: "$90",
            impression: "8,000",
            royalitiesPageRead: "$250.00",
            orders: "4,000",
            sales: "$100",
            pageLoad: "40,000",
            status: "Running",
          },
        },
      ],
    },
  ]);

  const targetAcosTemplate = (data) => {
    return (
      <div className="target">
 
          <InputText
            type="text"
            className="p-inputtext-sm"
            style={{ width: "130px" }}
            value={data.data.targetACOS}
            onKeyPress={(e) => {
              if (!/[0-9]/.test(e.key)) {
                e.preventDefault();
              }
            }}
            onChange={(e) => targetAconChange(e, data.data)} 
          />
   
      </div>
    );
  };

  const targetAconChange = (e, tacosData) => {
    const newValue = e.target.value;  
    const updatedNodes = nodes.map((node) => {
      if (node.data.name === tacosData.name) {
        return {
          ...node,
          data: {
            ...node.data,
            targetACOS: newValue , 
          },
        };
      }
      return node;
    });
    
    setNodes(updatedNodes); 
  };

  const imageTemplate = (node) => {
    return (
      <span style={{ display: "flex", alignItems: "center" }}>
        {node.data.imageUrl ? (
          <img
            src={node.data.imageUrl}
            alt={node.data.name}
            style={{ marginRight: "10px", width: "50px", height: "50px" }}
            className="ml-4"
          />
        ) : null}
        <div className="flex flex-column gap-1">
          <div style={{ fontWeight: "600" }}>{node.data.name}</div>
          <div className="" style={{ fontSize: "12px", fontWeight: "400" }}>
            {node.data.bookId}
          </div>
        </div>
      </span>
    );
  };

  const statusTemplate = (data) => {
    return (
      <div>
        {data.data.status ? (
          <div>
            {data.data.status === "live" ? (
              <div className="flex justify-content-between mb-1">
                <span style={{ color: "#237804" }}>{data.data.status}</span>
              </div>
            ) : data.data.status === "Blocked" ? (
              <div className="flex justify-content-between mb-1">
                <span style={{ color: "red" }}>{data.data.status}</span>
              </div>
            ) : data.data.status === "Running" ? (
              <div className="flex justify-content-between mb-1">
                <span style={{ color: "#237804" }}>{data.data.status}</span>
              </div>
            ) : (
              <div className="flex justify-content-between mb-1">
                <span style={{ color: "gray" }}>{data.data.status}</span>
              </div>
            )}
          </div>
        ) : (
          <div className="text-center">-</div>
        )}
      </div>
    );
  };
  const bsrTemplate = (data) => {
    return (
      <div>
        {data.data.bsr ? (
          <div>
            {data.data.bsr.map((bsr) => (
              <div className="flex justify-content-between mt-1">
                <div className="flex align-items-center">
                  {bsr.country === "UK" ? (
                    <img
                      src={uk}
                      width={20}
                      height={20}
                      style={{ borderRadius: "50%" }}
                      alt="UK Flag"
                    />
                  ) : (
                    <img
                      src={us}
                      width={20}
                      height={20}
                      style={{ borderRadius: "50%" }}
                      alt="US Flag"
                    />
                  )}
                  <span className="ml-2">{bsr.country}</span>
                </div>
                <div className="flex align-items-center">
                  <span
                    style={{
                      color: "rgb(17, 112, 255)",
                      textDecoration: "underline",
                    }}
                    className="mr-2"
                  >
                    {bsr.rank}
                  </span>
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 9.16667V13.1667C12 13.5203 11.8595 13.8594 11.6095 14.1095C11.3594 14.3595 11.0203 14.5 10.6667 14.5H3.33333C2.97971 14.5 2.64057 14.3595 2.39052 14.1095C2.14048 13.8594 2 13.5203 2 13.1667V5.83333C2 5.47971 2.14048 5.14057 2.39052 4.89052C2.64057 4.64048 2.97971 4.5 3.33333 4.5H7.33333"
                      stroke="#2563EB"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10 2.5H14V6.5"
                      stroke="#2563EB"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.6665 9.83333L13.9998 2.5"
                      stroke="#2563EB"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center">-</div>
        )}
      </div>
    );
  };

  const tagsTemplate = (data) => {
    return (
      <div>
        {data.data.tags ? (
          <div className="d-flex flex-wrap">
            <span className="addtags" onClick={() => addClickShow(data.data)}>
              Add Tag
            </span>
            {data.data.tags.map((tags ,index) => (
              <span className="tags mb-2 ">
                {tags}
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={()=>cancelTags(data.data ,index)}
                >
                  <path
                    d="M12 4L4 12"
                    stroke="#237804"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4 4L12 12"
                    stroke="#237804"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            ))}
          </div>
        ) : (
          <div className="text-center">-</div>
        )}
      </div>
    );
  };

  const addClickShow = (data) => {
    console.log(data);

    setAddTagDatas(data);

    setShow(true);
  };

  const createadsTemplate = (data) => {
    return (
      <div className="text-center">
        <i className="pi pi-plus " style={{ color: "green" }}></i>
      </div>
    );
  };

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const selectAll = (e) => {
    console.log(e);

    let _selectedNodes = {};

    if (e.checked) {
      nodes.forEach((node) => {
        _selectedNodes[node.key] = { checked: true, partialChecked: false };
        if (node.children) {
          node.children.forEach((child) => {
            _selectedNodes[child.key] = {
              checked: true,
              partialChecked: false,
            };
          });
        }
      });
    }
    console.log(_selectedNodes);

    setSelectedBooks(_selectedNodes);
  };

  const onSelectionChange = (e) => {
    setSelectedBooks(e.value);
  };

  const handleTagChange = (e) => {
    console.log(e.target.value);
    setTaginputValue(e.target.value);
  };

  const addtag = () => {
    const updatedNodes = nodes.map((node) => {
      if (node.data.name === addTagdatas.name) {
        return {
          ...node,
          data: {
            ...node.data,
            tags: [...node.data.tags, taginputValue], // Adding the new tag to the tags array
          },
        };
      }
      return node;
    });
    console.log(updatedNodes);
    setNodes(updatedNodes);
    setShow(false);
    setTaginputValue("");
    notification.open({
      placement: "bottom",
      description: "Success Add Tag",
      duration: 3, // Duration of the notification in seconds
    });
  };

  const cancelTags = (canceldata, index) => {
    const updatedNodes = nodes.map((node) => {
      if (node.data.name === canceldata.name) {
        return {
          ...node,
          data: {
            ...node.data,
            tags: node.data.tags.filter((_, i) => i !== index), // Remove the tag at the specific index
          },
        };
      }
      return node;
    });

    const data =updatedNodes.filter((node)=>node.data.name === canceldata.name )
    console.log(data[0]);
    
  
    setNodes(updatedNodes); // Update the state with the modified nodes
    setAddTagDatas(data[0].data)
    notification.open({
      placement: "bottom",
      description: "Success Delete Tag",
      duration: 100000, // Duration of the notification in seconds
    });
  };

  

  return (
    <div>
      <h3 className="font-bold text-center text-2xl p-4">Bookshelf</h3>
      <div className="card1 px-5 ">
        <TreeTable
          value={nodes}
          selectionMode="checkbox"
          resizableColumns
          scrollable
          showGridlines
          size="small"
          frozenWidth="480px"
          scrollHeight="calc(80vh - 100px)"
          reorderableColumns
          selectionKeys={selectedBooks}
          className="custom-scrollbar"
          onSelectionChange={(e) => setSelectedBooks(e.value)}
        >
          <Column
            field="name"
            // sortable
            // header="Book Details"
            expander
            frozen
            style={{ width: "480px" }}
            body={imageTemplate}
            className="firstColumn"
            header={
              <div style={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  checked={
                    Object.keys(selectedBooks).length ===
                    nodes.reduce(
                      (total, node) =>
                        total + 1 + (node.children ? node.children.length : 0),
                      0
                    )
                  }
                  onChange={selectAll}
                />
                <span style={{ marginLeft: "40px", width: "100%" }}>
                  Book Details
                </span>
              </div>
            }
            selectionMode="multiple"
          />
          <Column
            body={targetAcosTemplate}
            header="Target ACOS (%)"
            style={{ width: "169px" }}
          />
          <Column body={bsrTemplate} header="BSR" style={{ width: "168px" }} />
          <Column
            field="unitSold"
            sortable
            header="Unit Sold"
            style={{ width: "150px" }}
          />
          <Column
            field="royalities"
            sortable
            header="Royalities ($)"
            style={{ width: "150px" }}
          />
          <Column
            field="adspend"
            sortable
            header="Ad Spend ($)"
            style={{ width: "170px" }}
          />
          <Column
            field="price"
            sortable
            header="Price ($)"
            style={{ width: "150px" }}
          />
          <Column
            body={tagsTemplate}
            header="Tags"
            style={{ width: "400px" }}
          />
          <Column
            field="type"
            sortable
            header="Type"
            style={{ width: "150px" }}
          />
          <Column
            field="marketplace"
            sortable
            header="Marketplace"
            style={{ width: "160px" }}
          />
          <Column
            field="acos"
            sortable
            header="ACOS (%)"
            style={{ width: "150px" }}
          />
          <Column
            field="ctr"
            sortable
            header="CTR (%)"
            style={{ width: "150px" }}
          />
          <Column
            field="clicks"
            sortable
            header="Clicks"
            style={{ width: "150px" }}
          />
          <Column
            field="cr"
            sortable
            header="Conversion Rate (%)"
            style={{ width: "192px" }}
          />
          <Column field="cr" sortable header="CR" style={{ width: "150px" }} />
          <Column
            field="cpc"
            sortable
            header="CPC ($)"
            style={{ width: "150px" }}
          />
          <Column
            field="impression"
            sortable
            header="Impression"
            style={{ width: "150px" }}
          />
          <Column
            field="orders"
            sortable
            header="Orders"
            style={{ width: "150px" }}
          />
          <Column
            field="sales"
            sortable
            header="Sales ($)"
            style={{ width: "150px" }}
          />
          <Column
            field="pageLoad"
            sortable
            header="Page Load"
            style={{ width: "150px" }}
          />
          <Column
            field="royalitiesPageRead"
            sortable
            header="Royalities [Page Read] ($) "
            style={{ width: "220px" }}
          />
          <Column
            body={statusTemplate}
            // sortable
            header="Status"
            style={{ width: "96px" }}
          />
          <Column
            field="author"
            sortable
            header="Author"
            style={{ width: "168px" }}
          />
          <Column
            field="bookFormat"
            sortable
            header="Book Format"
            style={{ width: "124px" }}
          />
          <Column
            field="publishDate"
            sortable
            header="Publish Date"
            style={{ width: "168px" }}
          />
          <Column
            body={createadsTemplate}
            header="Create Ads"
            style={{ width: "150px" }}
          />
        </TreeTable>
      </div>

      <div className="pagination flex justify-content-between bg-white mx-5 ">
        <div className="pagination-leftside flex flex-row align-items-center justify-content-center ml-3">
          <span className="ms-2 content-center ">Show</span>
          <Dropdown
            value={perPage}
            onChange={(e) => setPerPage(e.value)}
            options={count}
            optionLabel="name"
            placeholder="50"
            className="mx-2 pagination-dropdown"
          />
          <span>of 7 total entries</span>
        </div>
        <div className="pagination-rightside ">
          <Paginator
            first={first}
            rows={rows}
            totalRecords={7}
            onPageChange={onPageChange}
          />
        </div>
      </div>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-100w"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Tags
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="fw-500" style={{ fontSize: "20px", color: "#111827" }}>
            Product Name
          </h5>
          <p style={{ fontSize: "16px", color: "#111827", fontWeight: "400" }}>
            {addTagdatas.name}
          </p>
          <div className="search" style={{ position: "relative" }}>
            <input
              className="w-100  form-control"
              style={{ borderRadius: "4px", height: "33px" }}
              placeholder="Search by tags..."
            ></input>
            <svg
              width="18"
              height="17"
              viewBox="0 0 18 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              
            >
              <path
                d="M8.16667 14.3333C11.8486 14.3333 14.8333 11.3486 14.8333 7.66667C14.8333 3.98477 11.8486 1 8.16667 1C4.48477 1 1.5 3.98477 1.5 7.66667C1.5 11.3486 4.48477 14.3333 8.16667 14.3333Z"
                stroke="#9CA3AF"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.5 16L12.875 12.375"
                stroke="#9CA3AF"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>

          <p
            style={{
              fontSize: "16px",
              color: "#111827",
              fontWeight: "700",
              marginTop: "1rem",
            }}
          >
            Tags
          </p>
          <div
            className="tags-box"
            style={{ position: "relative", border: "1px solid #ccc" ,height:"450px"}}
          >
            <div className="tags-content d-flex flex-wrap">
              {addTagdatas?.tags?.map((tag, index) => (
                <span className="tags ml-1 mb-2" key={index} >
                  {tag}
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={()=>cancelTags(addTagdatas ,index)}
                  >
                    <path
                      d="M12 4L4 12"
                      stroke="#237804"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4 4L12 12"
                      stroke="#237804"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              ))}
            </div>

            <input
              type="text"
              className="tags-input mt-1"
              placeholder="Separate tag with comma (,) or enter new line"
              value={taginputValue}
              onChange={handleTagChange}
              style={{
                border: "none",
                outline: "none",
                width: "100%",
                // height: "100%",
              }}
            />
          </div>

          <div className="text-end">
            <button className="addtagbutton mt-2 " onClick={addtag}>
              {" "}
              Add Tags
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <div className="footer  w-100 ">
        <div className="flex justify-content-between align-items-center mx-5 ">
          <div> &#169;2024, Aimosa</div>
          <div>
            <span>Privacy Policy</span>
            <span className="mx-3">Documentation</span>
            <span>Support</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Demo;
